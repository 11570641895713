<script setup lang="ts">
const { data } = useGlobalConfig()

const topNav = computed(() => {
  return {
    left: data.value?.top_nav_left.map((item) => {
      return {
        id: item.nav_link_id?.id,
        text: dt(item.nav_link_id?.translations)?.text ?? '',
        link: item.nav_link_id?.link,
      }
    }),
    right: data.value?.top_nav_right.map((item) => {
      return {
        id: item.nav_link_id?.id,
        text: dt(item.nav_link_id?.translations)?.text ?? '',
        link: item.nav_link_id?.link,
      }
    }),
  }
})

const mainNav = computed(() => {
  return {
    left: data.value?.main_nav_left.map((item) => {
      switch (item.collection) {
        case 'main_nav_group':
          return {
            id: item.id,
            text: dt(item.item.translations)?.name ?? '',
            items: item.item.items.map((subItem) => {
              return {
                background_image: subItem.main_nav_group_item_id.background_image,
                title: dt(subItem.main_nav_group_item_id?.translations)?.title ?? '',
                url: subItem.main_nav_group_item_id?.link,
                description: dt(subItem.main_nav_group_item_id?.translations)?.description ?? '',
              }
            }),
          }
        case 'nav_link':
        default:
          return {
            id: item.id,
            url: item.item.link,
            text: dt(item.item.translations)?.text ?? '',
          }
      }
    }),
    right: data.value?.main_nav_right.map((item) => {
      switch (item.collection) {
        case 'main_nav_group':
          return {
            id: item.id,
            text: dt(item.item.translations)?.name ?? '',
            items: item.item.items.map((subItem) => {
              return {
                background_image: subItem.main_nav_group_item_id.background_image,
                title: dt(subItem.main_nav_group_item_id?.translations)?.title ?? '',
                url: subItem.main_nav_group_item_id?.link,
                description: dt(subItem.main_nav_group_item_id?.translations)?.description ?? '',
              }
            }),
          }
        case 'nav_link':
        default:
          return {
            id: item.id,
            url: item.item.link,
            text: dt(item.item.translations)?.text ?? '',
          }
      }
    }),
  }
})

const dropdownStatus = ref(false)
let closeDropdownTimeout: any

const nuxtApp = useNuxtApp()

nuxtApp.hook('page:finish', () => {
  dropdownStatus.value = false
})

interface dropdownItem {
  background_image: string | null
  title: string
  url: string
  description: string
}
const dropdownItems = ref<dropdownItem[]>([])

function setDropdownItems(items: any) {
  dropdownItems.value = items
}

function openDropdown() {
  dropdownStatus.value = true
}

function closeDropdown() {
  clearTimeout(closeDropdownTimeout)
  closeDropdownTimeout = setTimeout(() => {
    dropdownStatus.value = false
  }, 300)
}

function clearDropdownTimeout() {
  clearTimeout(closeDropdownTimeout)
}

const { isInverted } = useNavbarTheme()

const lightNavbar = computed(() => isInverted.value && !dropdownStatus.value)

function handleDropDownItems(items: dropdownItem[]) {
  if (!items.length) {
    closeDropdown()
    return
  }
  setDropdownItems(items)
  openDropdown()
  clearDropdownTimeout()
}

const route = useRoute()

const isSignature = ref(false)

onMounted(() => {
  watch(() => route.path, () => {
    const elements = document.querySelector('*[data-is-signature-page=true]')
    isSignature.value = elements !== null
  }, { immediate: true })
})
</script>

<template>
  <nav :data-nav-invert="lightNavbar" class="fixed z-50 hidden w-full lg:block">
    <!-- <div class="fixed bottom-0 left-0 z-20 border-r border-t border-black rounded-tr-xl bg-white/50 p-4 p-4 text-black backdrop-blur-3xl">
      <strong>Dev: Navbar Color Mode Tracker</strong>
      <pre>Color Mode: {{ lightNavbar ? 'Light' : 'Dark' }}</pre>
      <pre>Active Tracking Block: {{ activeCoords }}</pre>
    </div> -->
    <AppPromoBanner />
    <!-- Top nav -->
    <div class="relative z-50 h-4rem w-full bg-starline-secondary">
      <Container class="nav-top h-full flex items-center justify-between uppercase">
        <!-- Top left nav -->
        <div class="flex gap-3rem">
          <NuxtLinkLocale v-for="link in topNav.left" :key="link.id" :to="link.link">
            {{ link.text }}
          </NuxtLinkLocale>
        </div>

        <!-- Top right nav -->
        <div class="flex gap-3rem">
          <NuxtLinkLocale v-for="link in topNav.right" :key="link.id" :to="link.link">
            {{ link.text }}
          </NuxtLinkLocale>
          <div>
            <AppHeaderLanguageSwitcher />
          </div>
        </div>
      </Container>
    </div>
    <!-- Bottom Nav -->

    <div
      class="relative z-40 h-6.4rem nav-light:text-neutral-black"
    >
      <div
        class="nav-blurred-gradient pointer-events-none absolute inset-x-0 top-0 -bottom-100px"
        :class="{ 'nav-light': lightNavbar }"
      />
      <div class="absolute inset-0 grid place-items-center">
        <NuxtLinkLocale to="/">
          <div class="stack">
            <LogoStarlineSignature
              class="transition-opacity duration-200" :class="isSignature ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-2'"
            />
            <LogoStarlineDark
              class="text-current transition duration-200" :class="isSignature ? 'opacity-0 -translate-y-2' : 'opacity-100 translate-y-0'"
            />
          </div>
        </NuxtLinkLocale>
      </div>
      <Container class="nav-bottom h-full flex items-center justify-between gap-4.5rem overflow-hidden">
        <!-- Bottom Nav Left -->
        <div class="flex gap-3rem">
          <NuxtLinkLocale
            v-for="link in mainNav.left" :key="link.id"
            :to="link.url"
            @mouseover="handleDropDownItems(link.items ?? [])"
            @mouseleave="closeDropdown"
          >
            {{ link.text }}
          </NuxtLinkLocale>
        </div>
        <!-- Bottom Nav Right -->
        <div class="flex gap-3rem">
          <NuxtLinkLocale
            v-for="link in mainNav.right" :key="link.id"
            :to="link.url"
            @mouseover="handleDropDownItems(link.items ?? [])"
            @mouseleave="closeDropdown"
          >
            {{ link.text }}
          </NuxtLinkLocale>
        </div>
      </Container>
    </div>
    <!-- Dropdown Menu -->
    <div
      class="absolute left-0 top-0 w-screen flex items-end pb-3.5rem pt-13rem transition duration-500"
      :class="dropdownStatus ? 'translate-y-0 opacity-100' : '-translate-y-500px opacity-0'"
      @mouseenter="clearDropdownTimeout"
      @mouseleave="closeDropdown"
    >
      <Container class="relative z-20 w-full flex justify-start gap-1.5rem">
        <AppHeaderDropdownItem
          v-for="(item, index) in dropdownItems"
          :key="index"
          :index="index"
          :title="item.title"
          :description="item.description"
          :url="item.url"
          :image="item.background_image"
          class="justify-self-stretch"
        />
        <div
          class="absolute left-1/2 h-1px w-screen bg-starline-primary/20 transition duration-500 delay-400 -top-27px -translate-x-1/2"
          :class="dropdownStatus ? 'opacity-100' : 'opacity-0'"
        />
      </Container>
      <div class="dropdown-background" />
      <div class="dropdown-bottom-gradient" />
    </div>
  </nav>
</template>

<style scoped>
/* Nav Top */
.nav-top a {
  @apply text-neutral-gray text-[1.1rem] font-400 transition duration-200 tracking-3px;
}

.nav-top a:hover {
  @apply text-starline-primary;
}

/* Nav Bottom */

.nav-bottom a {
  @apply relative text-current text-[1.4rem] font-400 transition duration-200 py-1rem;
}

.nav-bottom a:after {
  @apply absolute transition duration-200 opacity-0 h-10px w-full content-[''] -bottom-19px left-1/2 -translate-x-1/2 bg-starline-primary rounded-3px;
}

.nav-bottom a:hover {
  @apply text-starline-primary;
}

.nav-bottom a:hover:after {
  @apply opacity-100;
}

/* Dropdown menu */
.dropdown-background {
  @apply absolute z-10 left-0 top-0 w-full h-full bg-starline-secondary;
}
.dropdown-bottom-gradient {
  @apply absolute z-0 -bottom-30px left-0 w-screen h-100px;
  background: linear-gradient(to bottom, #6daadd 0%, transparent 100%);
}

.nav-blurred-gradient::after {
  transition: background-color 0.3s;
  content: '';
  position: absolute;
  inset: 0;
  z-index: -1;
  mask: linear-gradient(
    to bottom,
    hsl(0, 0%, 0%) 0%,
    hsla(0, 0%, 0%, 0.987) 8.1%,
    hsla(0, 0%, 0%, 0.951) 15.5%,
    hsla(0, 0%, 0%, 0.896) 22.5%,
    hsla(0, 0%, 0%, 0.825) 29%,
    hsla(0, 0%, 0%, 0.741) 35.3%,
    hsla(0, 0%, 0%, 0.648) 41.2%,
    hsla(0, 0%, 0%, 0.55) 47.1%,
    hsla(0, 0%, 0%, 0.45) 52.9%,
    hsla(0, 0%, 0%, 0.352) 58.8%,
    hsla(0, 0%, 0%, 0.259) 64.7%,
    hsla(0, 0%, 0%, 0.175) 71%,
    hsla(0, 0%, 0%, 0.104) 77.5%,
    hsla(0, 0%, 0%, 0.049) 84.5%,
    hsla(0, 0%, 0%, 0.013) 91.9%,
    hsla(0, 0%, 0%, 0) 100%
  );
  backdrop-filter: blur(200px);
  @apply bg-starline-secondary;
}

.nav-light.nav-blurred-gradient::after {
  @apply bg-neutral-white;
}

/* logo change animation */

.stack {
  display: grid;
  place-content: center;

  > * {
    grid-area: 1 / 1;
  }
}
</style>
